/* eslint-disable default-case */
import { Icon } from "@iconify/react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { httpsCallable } from "firebase/functions";
import { useRef, useState } from "react";
import editFill from "@iconify/icons-eva/edit-fill";
import { Link as RouterLink } from "react-router-dom";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
import activityFill from "@iconify/icons-eva/activity-fill";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db, auth, app, functions, storage } from "../../../Firebase/firebase";
import {
  ref as fireRef,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";

// ----------------------------------------------------------------------

// Modal Styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
};

export default function UserMoreMenu({ uid, name, jobTitle, bio, url }) {
  const deletedUserUID = uid;
  // User balance states

  const [updateBtnText, setUpdateBtnText] = useState("Update");

  const [userName, setUserName] = useState(name);
  const [userJobTitle, setUserJobTitle] = useState(jobTitle);
  const [userBio, setUserBio] = useState(bio);
  const [userUrl, setUserUrl] = useState(url);

  const [updateAlert, setUpdateAlert] = useState("");
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  // Modal states

  const [open, setOpen] = useState(false);

  const handleUpdate = async () => {
    setUpdateBtnText("Updating...");

    const washingtonRef = doc(db, "team", uid);

    if (userUrl !== url) {
      const spaceRef = fireRef(storage, `team/${uid}/${userUrl[0].name}`);
      const uploadTask = uploadBytesResumable(spaceRef, userUrl[0]);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          setUpdateBtnText("Update");
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            updateDoc(washingtonRef, {
              name: userName,
              jobTitle: userJobTitle,
              bio: userBio,
              url: url,
            })
              .then(() => {
                setUpdateBtnText("Update");
                setUpdateAlert("user balance has been updated");
              })
              .catch((error) => {
                setUpdateBtnText("Update");
                console.log(error);
              });
          });
        }
      );
    } else {
      updateDoc(washingtonRef, {
        name: userName,
        jobTitle: userJobTitle,
        bio: userBio,
        url: url,
      })
        .then(() => {
          setUpdateBtnText("Update");
          setUpdateAlert("user balance has been updated");
        })
        .catch((error) => {
          setUpdateBtnText("Update");
          console.log(error);
        });
    }

    // if (userIsVerified === true) {
    //   addDoc(collection(db, "mail"), {
    //     to: [email],
    //     template: {
    //       name: "verified",
    //       data: {
    //         name: firstName,
    //         support_email: "contact@ewob.online",
    //         action_url: "https://ewob.online/dashboard/dashboard.html",
    //       },
    //     },
    //   })
    //     .then(() => {
    //       updateDoc(userRef, {
    //         balance: Number(userCurrentBalance),
    //         isVerified: userIsVerified,
    //       })
    //         .then(() => {
    //           setUpdateBtnText("Update");
    //           setUpdateAlert("user balance has been updated");
    //         })
    //         .catch((err) => {
    //           setUpdateBtnText("Update");
    //           console.log(err.message);
    //         });
    //     })
    //     .catch((err) => {
    //       setUpdateBtnText("Update");
    //       console.log(err);
    //     });
    // } else {
    //   updateDoc(userRef, {
    //     balance: userCurrentBalance,
    //     isVerified: userIsVerified,
    //   })
    //     .then(() => {
    //       setUpdateBtnText("Update");
    //       setUpdateAlert("user balance has been updated");
    //     })
    //     .catch((err) => {
    //       setUpdateBtnText("Update");
    //       console.log(err.message);
    //     });
    // }
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUpdateAlert("");
    setUserName(name);
    setUserJobTitle(jobTitle);
    setUserBio(bio);
    setUserUrl(url);
  };

  const handleDelete = () => {
    deleteDoc(doc(db, "team", uid))
      .then(() => {
        alert("Team member has been deleted");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          sx={{ color: "text.secondary" }}
          onClick={() => handleDelete(deletedUserUID)}
        >
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: "text.secondary" }}
          onClick={handleOpen}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box component="form" sx={style} noValidate autoComplete="off">
              <div style={{ margin: "0 0 5px 0", textAlign: "center" }}>
                {updateAlert && (
                  <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    {updateAlert}
                  </Alert>
                )}

                <TextField
                  label="Full Name"
                  id="outlined-size-normal"
                  style={{ margin: "25px 0 15px 0", width: "90%" }}
                  onChange={(e) => setUserName(e.target.value)}
                />

                <TextField
                  label="Job Title"
                  id="outlined-size-normal"
                  style={{ margin: "0 0 15px 0", width: "90%" }}
                  onChange={(e) => setUserJobTitle(e.target.value)}
                />

                <TextField
                  label="Bio"
                  id="outlined-size-normal"
                  style={{ margin: "0 0 15px 0", width: "90%" }}
                  onChange={(e) => setUserBio(e.target.value)}
                />

                <TextField
                  id="outlined-size-normal"
                  style={{ margin: "0 0 15px 0", width: "90%" }}
                  onChange={(e) => setUserUrl(e.target.files)}
                  type="file"
                />
              </div>

              <Button
                variant="contained"
                onClick={handleUpdate}
                disabled={updateBtnText === "Updating..."}
              >
                {updateBtnText}
              </Button>
            </Box>
          </Fade>
        </Modal>

        {/* <MenuItem
          component={RouterLink}
          to={"userDetails/" + deletedUserUID}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Icon icon={activityFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="View transactions"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}
      </Menu>
    </>
  );
}
