import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCNeOWSiyI5yvKZ6D9DoZSk7ifedhqfeRE",
  authDomain: "metcon-e28d2.firebaseapp.com",
  projectId: "metcon-e28d2",
  storageBucket: "metcon-e28d2.appspot.com",
  messagingSenderId: "204398503408",
  appId: "1:204398503408:web:8330ac3216a646c9d76935",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const storage = getStorage(app);

export { db, auth, app, functions, storage };
