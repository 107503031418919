/* eslint-disable default-case */
// utils
import { filter, sample } from "lodash";
import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  TextField,
  Alert,
  AlertTitle,
  Box,
  Fade,
  Modal,
  Backdrop,
} from "@mui/material";
import { db, auth, app, functions, storage } from "../Firebase/firebase";
// material
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../components/_dashboard/user";
import { mockImgAvatar } from "../utils/mockImages";
import {
  ref as fireRef,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "jobTitle", label: "Job Title", alignRight: false },
  { id: "bio", label: "Bio", alignRight: false },
  { id: "url", label: "Photo URL", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [currentUser, setCurrentUser] = useState();
  const [adminToken, setAdminToken] = useState();
  const [usersList, setUsersList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        navigate("/login");
      }
    });
  }, [navigate]);

  useEffect(() => {
    const unSubscribe = onSnapshot(collection(db, "team"), (snapshot) => {
      const dbUsers = [];
      snapshot.forEach((doc) => {
        dbUsers.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setUsersList(dbUsers);
    });
    return unSubscribe;
  }, []);

  const users = usersList.map((user) => ({
    id: user?.id,
    name: user.name,
    jobTitle: user.jobTitle,
    bio: user.bio,
    url: user.url,
  }));

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(
    users,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const convertBalanceToCurrency = (balance) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(balance);
  };

  // add user
  const [openAddUserModel, setOpenAddUserModel] = useState(false);
  const [updateButton, setUpdateButton] = useState("Add user");
  const [addUserAlert, setaddUserAlert] = useState("");
  const [userName, setUserName] = useState("");
  const [userJobTitle, setUserJobTitle] = useState("");
  const [userBio, setUserBio] = useState("");
  const [userUrl, setUserUrl] = useState("");

  // Add user function
  const handleCreate = () => {
    setUpdateButton("Loading...");
    addDoc(collection(db, "team"), {
      name: userName,
      jobTitle: userJobTitle,
      bio: userBio,
      // url: userUrl,
    })
      .then((data) => {
        const spaceRef = fireRef(storage, `team/${data.id}/${userUrl[0].name}`);
        const uploadTask = uploadBytesResumable(spaceRef, userUrl[0]);

        const washingtonRef = doc(db, "team", data.id);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            setUpdateButton("Update");
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;
              case "storage/canceled":
                // User canceled the upload
                break;

              // ...

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              updateDoc(washingtonRef, {
                url: url,
                userID: data.id,
              })
                .then(() => {
                  setUpdateButton("Add user");
                  setaddUserAlert("user balance has been updated");
                })
                .catch((error) => {
                  setUpdateButton("Add user");
                  console.log(error);
                });
            });
          }
        );
      })
      .catch((error) => {
        setUpdateButton("Add user");
        console.log(error);
      });
  };

  const handleOpenAddUserModel = () => setOpenAddUserModel(true);

  const handleCloseAddUserModel = () => {
    setOpenAddUserModel(false);
    setaddUserAlert("");
  };

  // Modal Styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    overflow: "scroll",
    height: "auto",
    display: "block",
    bgcolor: "background.paper",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 400,
    border: "none",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Page title="User | Dashboard">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Users
            </Typography>
          </Stack>

          <div style={{ margin: "30px 0" }}>
            <Button variant="contained" onClick={handleOpenAddUserModel}>
              Add user
            </Button>
          </div>

          <div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openAddUserModel}
              onClose={handleCloseAddUserModel}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openAddUserModel}>
                <Box component="form" sx={style} noValidate autoComplete="off">
                  <div style={{ margin: "0 0 5px 0", textAlign: "center" }}>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginBottom: "5px",
                      }}
                    >
                      Add a new team member
                    </p>

                    {addUserAlert && (
                      <Alert severity="success">
                        <AlertTitle>Success</AlertTitle>
                        {addUserAlert}
                      </Alert>
                    )}

                    <TextField
                      label="Name"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      onChange={(e) => setUserName(e.target.value)}
                    />

                    <TextField
                      label="Job title"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      onChange={(e) => setUserJobTitle(e.target.value)}
                    />

                    <TextField
                      label="Bio"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      onChange={(e) => setUserBio(e.target.value)}
                    />

                    <TextField
                      type="file"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      onChange={(e) => setUserUrl(e.target.files)}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={handleCreate}
                      disabled={updateButton === "Add user" ? false : true}
                      style={{ width: "100%" }}
                    >
                      {updateButton}
                    </Button>
                  </div>
                </Box>
              </Fade>
            </Modal>
          </div>

          <Card>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={users.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const { id, name, jobTitle, bio, url } = row;

                        return (
                          <TableRow hover key={id} tabIndex={-1}>
                            <TableCell align="left">{name}</TableCell>
                            <TableCell align="left">{jobTitle}</TableCell>
                            <TableCell align="left">{bio}</TableCell>

                            <TableCell align="left">
                              {url ? (
                                <a href={url} target="_blank" rel="noreferrer">
                                  Click to view
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </TableCell>

                            <TableCell align="right">
                              <UserMoreMenu
                                uid={id}
                                name={name}
                                jobTitle={jobTitle}
                                bio={bio}
                                url={url}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    </div>
  );
}
