// utils
import { filter } from "lodash";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  AlertTitle,
} from "@mui/material";
import { db, auth } from "../Firebase/firebase";
// material
// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import { UserListHead, UserMoreMenu2 } from "../components/_dashboard/user";
import { useParams } from "react-router-dom";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "accountName", label: "Account Name", alignRight: false },
  { id: "accountNumber", label: "Account Number", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "bankName", label: "Bank Name", alignRight: false },
  { id: "category", label: "Category", alignRight: false },
  { id: "desc", label: "Description", alignRight: false },
  { id: "fullName", label: "Full Name", alignRight: false },
  { id: "isVerified", label: "Verified?", alignRight: false },
  { id: "otp", label: "OTP", alignRight: false },
  { id: "phoneNumber", label: "Phone Number", alignRight: false },
  { id: "resAddress1", label: "Address 1", alignRight: false },
  { id: "resAddress2", label: "Address 2", alignRight: false },
  { id: "resCity", label: "City", alignRight: false },
  { id: "resCountry", label: "Country", alignRight: false },
  { id: "resEmail", label: "Email", alignRight: false },
  { id: "resIban", label: "Iban", alignRight: false },
  { id: "resSwift", label: "Swift", alignRight: false },
  { id: "routingNumber", label: "Routing Number", alignRight: false },
  { id: "resPhone", label: "Phone", alignRight: false },
  { id: "resState", label: "State", alignRight: false },
  { id: "resZipCode", label: "Zip Code", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  {
    id: "transactionCurrency",
    label: "Transaction Currency",
    alignRight: false,
  },
  { id: "cotId", label: "COT Id", alignRight: false },
  { id: "imfId", label: "IMF Id", alignRight: false },
  { id: "dccPin", label: "DCC Pin", alignRight: false },
  { id: "taxId", label: "Tax Id", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserDetails() {
  const [currentUser, setCurrentUser] = useState();
  const [adminToken, setAdminToken] = useState();
  const [usersList, setUsersList] = useState([]);
  const navigate = useNavigate();
  const { uid } = useParams();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        navigate("/login");
      }
    });
  }, [navigate]);

  useEffect(() => {
    const unSubscribe = onSnapshot(
      collection(db, `users/${uid}/transactions`),
      (snapshot) => {
        const dbUsers = [];
        snapshot.forEach((doc) => {
          dbUsers.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        setUsersList(dbUsers);
      }
    );

    return unSubscribe;
  }, []);

  const users = usersList.map((user) => ({
    id: uid,
    accountName: user.accountName,
    accountNumber: user.accountNumber,
    amount: user.amount,
    bankName: user.bankName,
    category: user.category,
    desc: user.desc,
    fullName: user.fullName,
    isVerified: user.isVerified,
    otp: user.otp,
    phoneNumber: user.phoneNumber,
    resAddress1: user.resAddress1,
    resAddress2: user.resAddress2,
    resCity: user.resCity,
    resCountry: user.resCountry,
    resEmail: user.resEmail,
    resIban: user.resIban,
    resSwift: user.resSwift,
    routingNumber: user.routingNumber,
    resPhone: user.resPhone,
    resState: user.resState,
    resZipCode: user.resZipCode,
    status: user.status,
    transactionCurrency: user.transactionCurrency,
    type: user.type,
    transactionId: user.id,
    cotId: user.cotId,
    imfId: user.imfId,
    dccPin: user.dccPin,
    taxId: user.taxId,
    date: user.date,
    transactionBar: user.transactionBar,
  }));

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(
    users,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  // Convert balance to currency

  const convertBalanceToCurrency = (balance) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(balance);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied");
  };

  const [openAddIntlTransactionsModel, setOpenAddIntlTransactionsModel] =
    useState(false);

  const [
    openAddDomesticTransactionsModel,
    setOpenAddDomesticTransactionsModel,
  ] = useState(false);

  const [addUserAlert, setAddUserAlert] = useState("");

  const handleOpenAddIntlTransactionsModel = () =>
    setOpenAddIntlTransactionsModel(true);

  const handleOpenAddDomesticTransactionsModel = () =>
    setOpenAddDomesticTransactionsModel(true);

  const handleCloseAddIntlTransactions = () => {
    setOpenAddIntlTransactionsModel(false);
    setAddUserAlert("");
  };

  // Modal Styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    overflow: "scroll",
    height: "100%",
    display: "block",
    bgcolor: "background.paper",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 400,
    border: "none",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  const userDate = new Date();

  const [updateButton, setUpdateButton] = useState("Update");
  const [userAccountName, setUserAccountName] = useState("");
  const [userAccountNumber, setUserAccountNumber] = useState("");
  const [userBankName, setUserBankName] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userRoutingNumber, setUserRoutingNumber] = useState("");
  const [userAmount, setUserAmount] = useState("");
  const [userDecs, setUserDecs] = useState("");
  const [day, setDay] = useState(
    userDate.getDate().toString().padStart(2, "0")
  );
  const [month, setmonth] = useState(
    userDate.getMonth().toString().padStart(2, "0")
  );
  const [year, setYear] = useState(userDate.getFullYear());
  const [userCurrentBalance, setUserCurrentBalance] = useState(0);
  const [userTransactionCurency, setUserTransactionCurency] = useState("");
  const [updateAlert, setUpdateAlert] = useState("");

  // Get user balance
  useEffect(() => {
    const docRef = doc(db, "users", uid);

    const unSubscribe = getDoc(docRef).then((doc) => {
      if (doc.exists()) {
        setUserCurrentBalance(doc.data().balance);
        setUserTransactionCurency(doc.data().userCurrency);
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    });

    return unSubscribe;
  });

  const handleTransactionCreate = () => {
    setUpdateButton("Loading...");
    var mydate = new Date(`${year}-${month}-${day}T00:00:00`);
    const fireTmeStamp = Timestamp.fromDate(mydate);

    const userRef = doc(db, "users", uid);

    updateDoc(userRef, {
      balance: Number(userCurrentBalance) + Number(userAmount),
    })
      .then(() => {
        addDoc(collection(db, "users", uid, "transactions"), {
          date: fireTmeStamp,
          desc: userDecs,
          status: "success",
          bankName: userBankName,
          type: "credit",
          amount: Number(userAmount),
          accountName: userAccountName,
          accountNumber: userAccountNumber,
          phoneNumber: userPhoneNumber,
          routingNumber: userRoutingNumber,
          transactionCurrency: userTransactionCurency,
        })
          .then(() => {
            setUpdateButton("Update");
            setUpdateAlert("Balance has been updated");
          })
          .catch((err) => {
            setUpdateButton("Update");
            setUpdateAlert("Balance has been updated");

            console.log(err);
          });
      })
      .catch((err) => {
        setUpdateButton("Update");
        setUpdateAlert("Balance has been updated");

        console.log(err);
      });
  };

  return (
    <div>
      <Page title="User | Dashboard">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Transaction Details
            </Typography>
          </Stack>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              marginBottom: 30,
            }}
          >
            {/* <div style={{}}>
              <Button variant="contained">Add Domestic Transaction</Button>
            </div>
            <div style={{}}>
              <Button
                variant="contained"
                onClick={handleOpenAddIntlTransactionsModel}
              >
                Add International Transaction
              </Button>
            </div> */}

            <div style={{}}>
              <Button
                variant="contained"
                onClick={handleOpenAddIntlTransactionsModel}
              >
                Add Balance
              </Button>
            </div>
          </div>

          <div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openAddIntlTransactionsModel}
              onClose={handleCloseAddIntlTransactions}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openAddIntlTransactionsModel}>
                <Box component="form" sx={style} noValidate autoComplete="off">
                  <div style={{ margin: "0 0 5px 0", textAlign: "center" }}>
                    {updateAlert && (
                      <Alert severity="success">
                        <AlertTitle>Success</AlertTitle>
                        {updateAlert}
                      </Alert>
                    )}
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginBottom: "5px",
                      }}
                    >
                      Add a new transaction
                    </p>

                    <TextField
                      label="Account Name"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      type="text"
                      onChange={(e) => setUserAccountName(e.target.value)}
                    />

                    <TextField
                      label="Account Number"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      type="tel"
                      onChange={(e) => setUserAccountNumber(e.target.value)}
                    />

                    <TextField
                      label="Bank Name"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      type="text"
                      onChange={(e) => setUserBankName(e.target.value)}
                    />

                    <TextField
                      label="Receiver's Phone Number"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      type="tel"
                      onChange={(e) => setUserPhoneNumber(e.target.value)}
                    />

                    <TextField
                      label="Routing Number"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      type="tel"
                      onChange={(e) => setUserRoutingNumber(e.target.value)}
                    />

                    <TextField
                      label="Amount"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      type="number"
                      onChange={(e) => setUserAmount(e.target.value)}
                    />

                    <TextField
                      label="Description"
                      style={{ margin: "0 0 10px 0", width: "90%" }}
                      type="text"
                      onChange={(e) => setUserDecs(e.target.value)}
                    />

                    <FormControl
                      style={{
                        margin: "0 0 10px 0",
                        width: "30%",
                        marginRight: "5px",
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">Day</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Day"
                        onChange={(e) => setDay(e.target.value)}
                      >
                        <MenuItem value="01">01</MenuItem>
                        <MenuItem value="02">02</MenuItem>
                        <MenuItem value="03">03</MenuItem>
                        <MenuItem value="04">04</MenuItem>
                        <MenuItem value="05">05</MenuItem>
                        <MenuItem value="06">06</MenuItem>
                        <MenuItem value="07">07</MenuItem>
                        <MenuItem value="08">08</MenuItem>
                        <MenuItem value="09">09</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="11">11</MenuItem>
                        <MenuItem value="12">12</MenuItem>
                        <MenuItem value="13">13</MenuItem>
                        <MenuItem value="14">14</MenuItem>
                        <MenuItem value="15">15</MenuItem>
                        <MenuItem value="16">16</MenuItem>
                        <MenuItem value="17">17</MenuItem>
                        <MenuItem value="18">18</MenuItem>
                        <MenuItem value="19">19</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="21">21</MenuItem>
                        <MenuItem value="22">22</MenuItem>
                        <MenuItem value="23">23</MenuItem>
                        <MenuItem value="24">24</MenuItem>
                        <MenuItem value="25">25</MenuItem>
                        <MenuItem value="26">26</MenuItem>
                        <MenuItem value="27">27</MenuItem>
                        <MenuItem value="28">28</MenuItem>
                        <MenuItem value="29">29</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                        <MenuItem value="31">31</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl
                      style={{
                        margin: "0 0 10px 0",
                        width: "30%",
                        marginRight: "5px",
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Month
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Month"
                        onChange={(e) => setmonth(e.target.value)}
                      >
                        <MenuItem value="01">Jan</MenuItem>
                        <MenuItem value="02">Feb</MenuItem>
                        <MenuItem value="03">Mar</MenuItem>
                        <MenuItem value="04">Apr</MenuItem>
                        <MenuItem value="05">May</MenuItem>
                        <MenuItem value="06">Jun</MenuItem>
                        <MenuItem value="07">Jul</MenuItem>
                        <MenuItem value="08">Aug</MenuItem>
                        <MenuItem value="09">Sep</MenuItem>
                        <MenuItem value="10">Oct</MenuItem>
                        <MenuItem value="11">Nov</MenuItem>
                        <MenuItem value="12">Dec</MenuItem>
                      </Select>
                    </FormControl>

                    <TextField
                      label="Year"
                      style={{
                        margin: "0 0 10px 0",
                        width: "30%",
                        // marginRight: "5px",
                      }}
                      type="number"
                      onChange={(e) => setYear(e.target.value)}
                    />

                    {/* <FormControl
                      fullWidth
                      style={{ margin: "0 0 15px 0", width: "90%" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Transaction Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select transaction type"
                      >
                        <MenuItem value="debit">Debit</MenuItem>
                        <MenuItem value="credit">Credit</MenuItem>
                      </Select>
                    </FormControl> */}

                    {/* <FormControl
                      fullWidth
                      style={{ margin: "0 0 15px 0", width: "90%" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select transaction type"
                      >
                        <MenuItem value="success">Success</MenuItem>
                        <MenuItem value="waiting">Waiting</MenuItem>
                        <MenuItem value="failed">Failed</MenuItem>
                      </Select>
                    </FormControl> */}

                    <Button
                      variant="contained"
                      onClick={handleTransactionCreate}
                      disabled={updateButton === "Loading..." ? true : false}
                      style={{ width: "100%" }}
                    >
                      {updateButton}
                    </Button>
                  </div>
                </Box>
              </Fade>
            </Modal>
          </div>

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={users.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          accountName,
                          accountNumber,
                          amount,
                          bankName,
                          category,
                          desc,
                          fullName,
                          isVerified,
                          otp,
                          phoneNumber,
                          resAddress1,
                          resAddress2,
                          resCity,
                          resCountry,
                          resEmail,
                          resIban,
                          resSwift,
                          routingNumber,
                          resPhone,
                          resState,
                          resZipCode,
                          status,
                          transactionCurrency,
                          type,
                          transactionId,
                          date,
                          cotId,
                          imfId,
                          dccPin,
                          taxId,
                          transactionBar,
                        } = row;

                        return (
                          <TableRow hover key={transactionId} tabIndex={-1}>
                            <TableCell align="left">{accountName}</TableCell>
                            <TableCell align="left">{accountNumber}</TableCell>
                            <TableCell align="left">
                              {convertBalanceToCurrency(amount)}
                            </TableCell>
                            <TableCell align="left">{bankName}</TableCell>
                            <TableCell align="left">{category}</TableCell>
                            <TableCell align="left">{desc}</TableCell>
                            <TableCell align="left">{fullName}</TableCell>
                            <TableCell align="left">
                              {isVerified ? "true" : "false"}
                            </TableCell>
                            <TableCell align="left">
                              {otp}{" "}
                              <button onClick={() => handleCopy(otp)}>
                                copy
                              </button>
                            </TableCell>
                            <TableCell align="left">{phoneNumber}</TableCell>
                            <TableCell align="left">{resAddress1}</TableCell>
                            <TableCell align="left">{resAddress2}</TableCell>
                            <TableCell align="left">{resCity}</TableCell>
                            <TableCell align="left">{resCountry}</TableCell>
                            <TableCell align="left">{resEmail}</TableCell>
                            <TableCell align="left">{resIban}</TableCell>
                            <TableCell align="left">{resSwift}</TableCell>
                            <TableCell align="left">{routingNumber}</TableCell>
                            <TableCell align="left">{resPhone}</TableCell>
                            <TableCell align="left">{resState}</TableCell>
                            <TableCell align="left">{resZipCode}</TableCell>
                            <TableCell align="left">{status}</TableCell>
                            <TableCell align="left">
                              {transactionCurrency}
                            </TableCell>
                            <TableCell align="left">
                              {cotId}{" "}
                              <button onClick={() => handleCopy(cotId)}>
                                copy
                              </button>
                            </TableCell>
                            <TableCell align="left">
                              {imfId}{" "}
                              <button onClick={() => handleCopy(imfId)}>
                                copy
                              </button>
                            </TableCell>
                            <TableCell align="left">
                              {dccPin}{" "}
                              <button onClick={() => handleCopy(dccPin)}>
                                copy
                              </button>
                            </TableCell>
                            <TableCell align="left">
                              {taxId}{" "}
                              <button onClick={() => handleCopy(taxId)}>
                                copy
                              </button>
                            </TableCell>
                            <TableCell align="left">{type}</TableCell>

                            <TableCell align="right">
                              <UserMoreMenu2
                                amount={amount}
                                desc={desc}
                                status={status}
                                uid={uid}
                                accountName={accountName}
                                transactionId={transactionId}
                                date={date}
                                transactionBar={transactionBar}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    </div>
  );
}
